import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import styled from "styled-components"
import { node } from "prop-types"
import BlogPostPreviewGrid from "../components/blogPostPreviewGrid"
import Layout from "../components/layout"


//Todo: her skal de ikke være noe særlig JS, flytt alt til komponentene blogpostpreveiw og blogpostpreviewgrid



const BlogPage = props => {
  const { data, errors } = props




  if (errors) {
    return (
      <div>
        <h1>
          There be errors (bruk heller GraphyQlErrorList komponent eller noe
          slik), her må vi også håndtere feil med like slugs siden vi kun genererer URL ved bruk av slug
        </h1>
      </div>
    )
  }


  return (
    <Layout>

    <div>
      <SEO title="blog" />
      <div>
        <h1>Blogg data</h1>
        {/*Sjekker først om data eksisterer, så kjøres en if-else via ternary operator. Data sendes til komponenter som håndterer løkkene */}
        {data ? (
          <BlogPostPreviewGrid blogPosts={data} key={node.id} />
        ) : (
            <p>Kunne ikke hente bloggdata</p>
          )
        }
      </div>


    </div>
    </Layout>

  )

}

export const query = graphql`
  query BlogPageQuery {
    posts: allSanityPost(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
        alt
        asset {
      fixed(width: 250, height: 250) {
        ...GatsbySanityImageFixed
      }
  }
}
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`
//stjålet fra https://github.com/sanity-io/example-company-website-gatsby-sanity-combo/blob/6b7df9d1894fec828d265e59d9a3ded1e38473cc/web/src/lib/helpers.js#L7
//litt usikker på nøyaktiv hvordan den virker 
// bør legges i hjelpeklasse når vi skjønner den 
export default BlogPage



