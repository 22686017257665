import React from "react"
import Img from 'gatsby-image'
const BlockContent = require('@sanity/block-content-to-react')



const PreviewFunction = ({ blogPostsData }) => {
  //Legger all blogPostData i en const så det blir mindre verbost å kalle
  const postData = blogPostsData.blogPosts
  //If-else for å sjekke om bilder finnes, hvis de ikke finnes vises bare innhol
  //uten if-else for bilder vil siden kræsje på alle bloggposter uten bilder
  return (
    <div className='box'>
      {postData.mainImage && (
        <Img fixed={postData.mainImage.asset.fixed}
          alt={postData.mainImage.alt}
        />)}

      <h1>{postData.title}</h1>
      {postData._rawExcerpt && (
        <BlockContent blocks={postData._rawExcerpt} />
      )}

    </div>
  )

}

const BlogPostPreview = props => {

  return (
    <>
      {props ? (
        <PreviewFunction blogPostsData={props} key={props.id} />
      ) : (
          <p>Kunne ikke hente bloggdata</p>
        )
      }
    </>
  )
}
export default BlogPostPreview
