import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import SEO from "./seo"
import styled from "styled-components"
import { node } from "prop-types"
import BlogPostPreview from "./BlogPostPreview"

const BlogPostPreviewGrid = ({blogPosts}) => {
    return (
    <div>   
     {blogPosts.posts.edges.map (({ node }) => (
                <BlogPostPreview blogPosts={node} key={node.id}/> 
            ))}
    </div>
    )

}
export default BlogPostPreviewGrid